export default function () {
    const onSlideDownEnd = (button, panel) => {
      return function onSlideDownTransitionEnd() {
        if (button.classList.contains('active')) {
          panel.style.display = '';
          panel.style.height = '';
        }
      };
    };
  
    const onSlideUpEnd = (button, panel) => {
      return function onSlideUpTransitionEnd() {
        if (!button.classList.contains('active')) {
          panel.style.display = 'none';
          panel.style.height = '';
        }
      };
    };
  
    const removeAllEventListeners = (panel) => {
      panel.removeEventListener('transitionend', onSlideUpEnd);
      panel.removeEventListener('transitionend', onSlideDownEnd);
    };
  
    const slideDown = (button, panel) => {
      button.classList.add('active');
      button.setAttribute('aria-expanded', 'true');
  
      panel.style.height = '0px';
      panel.style.display = 'block';
  
      setTimeout(() => {
        panel.style.height = panel.scrollHeight + 'px';
      }, 1);
  
      removeAllEventListeners(panel);
      panel.addEventListener('transitionend', onSlideDownEnd(button, panel), { once: true });
    };
  
    const slideUp = (button, panel) => {
      button.classList.remove('active');
      button.setAttribute('aria-expanded', 'false');
  
      panel.style.height = panel.scrollHeight + 'px';
  
      setTimeout(() => {
        panel.style.height = '0px';
      }, 1);
  
      removeAllEventListeners(panel);
      panel.addEventListener('transitionend', onSlideUpEnd(button, panel), { once: true });
    };
  
    Array.from(document.querySelectorAll('[data-slide-toggle]')).forEach(button => {
      const panel = document.querySelector(button.dataset.slideToggle);

      if (!button.classList.contains('active')) {
        panel.style.display = 'none';
      }
      panel.classList.add('overflow-hidden', 'transition-all', 'duration-300');
  
      button.addEventListener('click', () => {
        if (!button.classList.contains('active')) {
          slideDown(button, panel);
        } else {
          slideUp(button, panel);
        }
  
        if (button.dataset.slideToggleGroup) {
          Array.from(document.querySelectorAll('[data-slide-toggle-group="' + button.dataset.slideToggleGroup + '"]'))
            .filter(button2 => button2 !== button)
            .forEach(button2 => {
            const panel2 = document.querySelector(button2.dataset.slideToggle);
            if (button2.classList.contains('active')) {
              slideUp(button2, panel2);
            }
          });
        }
      });
  
      button.setAttribute('aria-expanded', button.classList.contains('active') ? 'true' : 'false');
    });
  }
  