export default function () {
    const menu = document.getElementById('nav-mobile');
    const hamburger = document.querySelector('.hamburger');
    const anchorLinks = document.querySelectorAll('.js-anchor-link');
    const logo = document.querySelector('.logo');
    const body = document.querySelector('body');

    const toggleMenu = () => {      
        menu.classList.toggle('nav-open');
        body.classList.toggle('overlay-active');
        hamburger.classList.toggle('open');
        
        if (menu.classList.contains('nav-open')) {
            hamburger.ariaLabel = hamburger.dataset.closeLabel;

            logo.dataset.menuOpen = 'true';
        } else {
            hamburger.ariaLabel = hamburger.dataset.openLabel;

            logo.dataset.menuOpen = 'false';
        }       
    };

    hamburger.addEventListener('click', toggleMenu);
    anchorLinks.forEach(link => {
        link.addEventListener('click', toggleMenu);
    });
}