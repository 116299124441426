export default function () {
    const menu = document.getElementById('menu-header');
    const border = document.querySelector('.js-border-header');
	const menuHeight = menu.offsetHeight || 92;
	const footer = document.getElementById('footer');
	const pageHeight = document.documentElement.offsetHeight;
	let hideMenuHeight = pageHeight - footer.offsetHeight - 125;

	const showHeader = () => {	
		window.addEventListener("resize", function () {
			hideMenuHeight = pageHeight - footer.offsetHeight - 125;
		});
		
		if (window.scrollY > menuHeight) {
			border.classList.remove('opacity-0');
		} else {
			border.classList.add('opacity-0');
		}
		
		if (window.scrollY >= hideMenuHeight) {
			menu.classList.add('opacity-0');
		} else {
			menu.classList.remove('opacity-0');
		}
	};

	window.addEventListener('scroll', showHeader);
}

