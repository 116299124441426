import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default function () {
    let mm = gsap.matchMedia();     

    mm.add("(max-width: 1439px)", () => {

        gsap.to('.js-parallax-content', {
            yPercent: -25,
            scrollTrigger: {
                trigger: '.js-parallax-trigger',
                start: '10% top',
                end: 'bottom 25%',
                scrub: 2,
            } 
        });
    });

    mm.add("(min-width: 1440px)", () => {
        gsap.to('.js-parallax-content', {
            yPercent: -35,
            scrollTrigger: {
                trigger: '.js-parallax-trigger',
                start: '10% top',
                end: 'bottom 25%',
                scrub: 2,
            } 
        });
    });  
}