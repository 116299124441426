import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

export default function () {
  function carrousel() {
    const swiperTestimonies = new Swiper('.swiper-testimonies', {       
        navigation: {
          nextEl: ".swiper-button-nav-next",
          prevEl: ".swiper-button-nav-prev"
        },
        slidesPerView: '1',
        slidesPerGroup: '1',
        modules: [Navigation],
        breakpoints: {
          // when window width is >= 768px
          768: {
            slidesPerView: 'auto',
        slidesPerGroup: '2',
          }
      }
    });
  }
  carrousel();
}