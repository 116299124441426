import '/assets/styles/main.pcss';

import LazyLoad from 'vanilla-lazyload';
import Alpine from 'alpinejs';

import carrousel from './components/carrousel.js';
import header from './components/header.js';
import mobileMenu from './components/mobileMenu.js';
import slideToggle from './components/slideToggle.js';
import scrollTrigger from './components/scrollTrigger.js';

window.lazyloadInstance = new LazyLoad();
window.Alpine = Alpine;

Alpine.start();

carrousel();
header();
mobileMenu();
scrollTrigger();
slideToggle();

document.addEventListener('DOMContentLoaded', () => {});
